<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Logout',
  computed: {
    ...mapGetters('user', ['homeRoute']),
  },
  methods: {
    ...mapActions('user', ['logout']),
  },
  async created() {
    const loggedOut = await this.logout();
    if (loggedOut) {
      this.$router.push({ name: this.homeRoute });
    }
  },
};
</script>
